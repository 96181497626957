import React from 'react';

interface ApiReferenceParameterProps {
  parameter: any;
}

export const ApiReferenceParameter = ({ parameter }: ApiReferenceParameterProps) => {
  return (
    <div className="apiReference__parameter">
      <div className="apiReference__parameter-item apiReference__parameter-name">
        <span>{parameter.name}</span>
      </div>
      <div className="apiReference__parameter-item apiReference__parameter-description">
        {parameter.isRequired && <span>Required</span>}
        <div>{parameter.description}</div>
        <div className="apiReference__parameter-type">
          <pre
            dangerouslySetInnerHTML={{ __html: parameter.type }}
          ></pre>
        </div>
      </div>
    </div>
  );
};
