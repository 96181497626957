import { ApiRequestMethod } from "../types";
import { ORIGIN_URL } from "./constants";
import { exampleDocumentActivities } from "./example";

export const getDocumentActivityRequest = {
  title: "Get document activity",
  type: "getDocumentActivity",
  description: "Get activity of the document",
  url: ORIGIN_URL + "/documents/:id/activities",
  method: ApiRequestMethod.GET,
  path: [
    {
      name: "id",
      description: "Id of the Document",
      isRequired: true,
      type: "UUID",
    },
  ],
  response: exampleDocumentActivities,
  attributes: [
    {
      name: "id",
      description: "Link to the document view",
      type: "UUID",
    },
    {
      name: "type",
      description: "Activity type",
      type: '"create" \n| "update" \n| "send" \n| "view" \n| "sign" \n| "complete" \n| "revert" \n| "preparersign"',
    },
    {
      name: "sourceIP",
      description: "IP address of activity",
      type: "IPv4 | IPv6",
    },
    {
      name: "signers",
      description: "List of signers",
      type: '[\n {\n  name: string,\n  email: string\n }\n]'
    },
    {
      name: "documentId",
      description: "Document's ID",
      type: "UUID",
    },
    {
      name: "createdAt",
      description: "Date of activity",
      type: "timestamp",
    },
  ],
};
