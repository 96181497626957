import { ApiRequestMethod } from "types";
import { authentication } from "./authentication";
import { bulkSigningRequest } from "./bulkSigningRequest";
import { ORIGIN_URL } from "./constants";
import { deleteDocumentRequest } from "./deleteDocumentRequest";
import { deleteTemplateRequest } from "./deleteTemplateRequest";
import { getCurrentDocumentDownloadLinkRequest } from "./getCurrentDocumentDownloadLinkRequest";
import { getDocumentActivityRequest } from "./getDocumentActivityRequest";
import { getDocumentListRequest } from "./getDocumentListRequest";
import { getDocumentShareLinkRequest } from "./getDocumentShareLinkRequest";
import { getRecentDocumentRequest } from "./getRecentDocumentRequest";
import { getSignersRequest } from "./getSignersRequest";
import { getTemplateFileRequest } from "./getTemplateFileRequest";
import { getUserRequest } from "./getUserRequest";
import { listTemplatesRequest } from "./listTemplatesRequest";
import { renameDocumentRequest } from "./renameDocumentRequest";
import { revertDocumentRequest } from "./revertDocumentRequest";
import { sendSignRemindersRequest } from "./sendSignRemindersRequest";
import { shareDocumentRequest } from "./shareDocumentRequest";
import { signingRequest } from "./signingRequest";
import teams from "./teams";
import folders from "./folders";

const data = {
  user: getUserRequest,
  signigRequest: signingRequest,
  bulkSigning: bulkSigningRequest,
  getDocumentList: getDocumentListRequest,
  getSigners: getSignersRequest,
  getCurrentDocumentDownloadLink: getCurrentDocumentDownloadLinkRequest,
  getTemplateFile: getTemplateFileRequest,
  getDocumentActivity: getDocumentActivityRequest,
  getDocumentShareLink: getDocumentShareLinkRequest,
  getRecentDocument: getRecentDocumentRequest,
  renameDocument: renameDocumentRequest,
  revertDocument: revertDocumentRequest,
  sendSignReminders: sendSignRemindersRequest,
  deleteDocument: deleteDocumentRequest,
  deleteTemplate: deleteTemplateRequest,
  shareDocument: shareDocumentRequest,
  listTemplates: listTemplatesRequest,

  ...teams,
  ...folders,

  authentication: authentication,
  webhooks: {
    title: "Webhook",
    type: "webhooks",
    description: `
      Authentication is made with your API Key which can be found in your account settings.
      You will have to add your API Key to every call you make to our API. This parameter is
      always required. We'll return an error if the key is either missing or invalid.
      Your API key is what identifies your account, so be sure to keep it secret! Please
      don’t share it with anyone outside of your organization.
  `,
    url:
      ORIGIN_URL +
      "/hooks/subscribe | " +
      ORIGIN_URL +
      "/hooks/unsubscribe/:id",
    method: `${ApiRequestMethod.GET} | ${ApiRequestMethod.DELETE}`,
  },
};

export default data;
