import {deleteResult} from "./deleteResult";
import {ORIGIN_URL} from "./constants";
import {ApiRequestMethod} from "../types";
import {exampleDeleteDocumentRequest} from "./example";

export const deleteDocumentRequest = {
  title: 'Delete document',
  type: 'deleteDocument',
  description: 'The endpoint allows you to remove the document',
  url: ORIGIN_URL + '/documents',
  method: ApiRequestMethod.DELETE,
  parameters: [{
    name: 'documentId',
    description: 'Document ID',
    type: 'UUID',
    isRequired: true,
  }],
  exampleRequest: exampleDeleteDocumentRequest,
  response: deleteResult,
};
