import {ORIGIN_URL} from "./constants";
import {ApiRequestMethod} from "../types";
import {exampleDeleteTemplateRequest} from "./example";
import {deleteResult} from "./deleteResult";

export const deleteTemplateRequest = {
  title: 'Delete template',
  type: 'deleteTemplate',
  description: 'The endpoint allows you to remove the template',
  url: ORIGIN_URL + '/documents/api/templates',
  method: ApiRequestMethod.DELETE,
  parameters: [{
    name: 'templateId',
    description: 'Template ID',
    type: 'UUID',
    isRequired: true,
  }],
  exampleRequest: exampleDeleteTemplateRequest,
  response: deleteResult,
};
