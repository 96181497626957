import {ApiRequestMethod} from "../types";
import {ORIGIN_URL} from "./constants";
import {exampleDocument} from "./example";
import {documentAttributes} from "./documentAttributes";

export const revertDocumentRequest = {
  title: 'Cancel Signature Request',
  description: 'Cancel Signature Request API call will allow users to cancel an active signature request (basically withdraw the document send).',
  type: 'revertDocument',
  method: ApiRequestMethod.PATCH,
  url: ORIGIN_URL + '/documents/:documentId/revert',
  path: [{
    name: 'documentId',
    description: 'Document ID',
    type: 'UUID',
    isRequired: true,
  }],
  attributes: documentAttributes,
  response: exampleDocument,
};
