import {ApiRequestMethod} from "../types";
import {ORIGIN_URL} from "./constants";
import {exampleDocumentShareLink} from "./example";

export const getDocumentShareLinkRequest = {
  title: 'Get document share link',
  type: 'getDocumentShareLink',
  description: 'Get share link of the document',
  response: exampleDocumentShareLink,
  method: ApiRequestMethod.GET,
  url: ORIGIN_URL + '/document_sign/documents/:id/share_url',
  path: [{
    name: 'id',
    description: 'ID of the document',
    isRequired: true,
    type: 'UUID',
  }],
  attributes: [{
    name: 'result',
    description: 'Link to the document view',
    type: 'URL',
  }]
};
