import {FRONTEND_URL} from "./constants";

export const exampleUser = {
  data: {
    appSumoStatus: "standard",
    avatarUrl: null,
    email: "user@example.example",
    id: "991a8353-ecb6-4bf1-9c23-b56b17a6cb9e",
    name: "username",
    status: "active",
    role: "owner",
  },
};

export const exampleDocument = {
  codeAccess: null,
  createdAt: "2021-07-27T09:36:34.824Z",
  deletedAt: null,
  errorText: null,
  fields: [
    {
      checked: null,
      coordinateX: 77.3903732299805,
      coordinateY: 126.555679321289,
      createdAt: "2021-07-27T09:37:32.683Z",
      dateFormat: null,
      documentId: "2665605c-cf2d-461a-8003-50b60732a494",
      fontFamily: null,
      fontSize: null,
      height: 25,
      id: "991a8353-ecb6-4bf1-9c23-b56b17a6cb9e",
      pageNumber: 1,
      required: true,
      requisiteId: null,
      signed: false,
      signerId: "d112370a-74b8-44de-84a5-ed124e9c174e",
      text: null,
      type: "sign",
      updatedAt: "2021-07-27T09:37:32.683Z",
    },
  ],
  filesUploaded: true,
  id: "2665605c-cf2d-461a-8003-50b60732a494",
  isFromFormRequest: false,
  isOrdered: false,
  message: null,
  originalFileName: null,
  originalFileUrl: null,
  parts: [
    {
      documentId: "2665605c-cf2d-461a-8003-50b60732a494",
      errorText: null,
      files: [
        {
          fileKey: "fileKey",
          id: "fileId",
          order: 1,
        },
      ],
      filesUploaded: true,
      id: "c5b60ea6-e6f0-4911-a6ba-f82d4b810301",
      order: 1,
      originalFileName: "test.pdf",
      originalFileUrl:
        "https://example.com/doc/97066b2e-1853-4f3a-927c-2b61e454a58e/19e72610-980a-41ae-a2f3-3a7aeb095cb8.pdf",
      pdfFileKey:
        "pdf/97066b2e-1853-4f3a-927c-2b61e454a58e/19e72610-980a-41ae-a2f3-3a7aeb095cb8.pdf",
      pdfMetadata: {
        height: 842,
        layout: "portrait",
        mediaBox: [0, 0, 595.25, 842],
        offsetX: 0,
        offsetY: 0,
        pageNumber: 1,
        rotate: 0,
        size: [595.25, 842],
        width: 595.25,
      },
    },
  ],
  pdfFileKey:
    "pdf/97066b2e-1853-4f3a-927c-2b61e454a58e/19e72610-980a-41ae-a2f3-3a7aeb095cb8.pdf",
  pdfMetadata: {
    height: 842,
    layout: "portrait",
    mediaBox: [0, 0, 595.25, 842],
    offsetX: 0,
    offsetY: 0,
    pageNumber: 1,
    rotate: 0,
    size: [595.25, 842],
    width: 595.25,
  },
  recipients: null,
  resultPdfFileKey: null,
  shareLink: null,
  signers: [
    {
      documentId: "2665605c-cf2d-461a-8003-50b60732a494",
      email: "signer@example.example",
      id: "929fe226-753b-4cd7-b5ae-b095ccad967b",
      isFinished: false,
      isPreparer: false,
      name: "anton1",
      order: 2,
      role: null,
      userId: "db0d6b70-35b3-4095-a742-776527b7c1f0",
      isReceivingReminders: true,
      isDeclined: false,
    },
  ],
  status: "awaiting",
  templateId: null,
  testMode: false,
  title: "test doc auth",
  type: "others",
  updatedAt: "2021-07-27T09:37:38.316Z",
};

export const exampleSigner = {
  data: {
    id: "db0d6b70-35b3-4095-a742-776527b7c1f0",
    name: "John Doe",
    order: 1,
    role: null,
    email: "john.doe@domain.ltd",
  },
};

export const exampleSigningRequestReq = {
  title: "example document",
  message: "example message",
  signers: [
    {
      role: "role1",
      name: "signer1",
      email: "signer1@example.example",
      fields: {
        customerPhone: "+79001101010",
        reason: "Due to personal purposes I need it",
        agreeAllTerms: true,
      },
    },
  ],
  testMode: true,
  templateId: "db0d6b70-35b3-4095-a742-776527b7c1f0",
};

export const exampleDeleteDocumentRequest = {
  documentId: '1964b33b-f60b-4104-af30-505e730a9d18',
}

export const exampleDeleteTemplateRequest = {
  templateId: '1964b33b-f60b-4104-af30-505e730a9d18',
}

export const exampleDocumentActivities = [
  {
    "id": "eca49a9d-a07c-4308-ab9b-dcf964e62582",
    "type": "create",
    "sourceIP": "::1",
    "signers": null,
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:40:20.087Z"
  },
  {
    "id": "4d3b96c0-b169-4a85-b1f6-ab8b260596b1",
    "type": "send",
    "sourceIP": "::1",
    "signers": [
      {
        "name": "Maksim Lebedev",
        "email": "maksim@domain.ltd"
      }
    ],
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:40:41.620Z"
  },
  {
    "id": "881bd340-01a3-43fb-9b61-848750d79387",
    "type": "view",
    "sourceIP": "::1",
    "signers": [
      {
        "name": "Maksim Lebedev",
        "email": "maksim@domain.ltd"
      }
    ],
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:40:56.320Z"
  },
  {
    "id": "fa482139-50c5-4a38-8edf-852c42eb732d",
    "type": "sign",
    "sourceIP": "::1",
    "signers": [
      {
        "name": "Maksim Lebedev",
        "email": "maksim@domain.ltd"
      }
    ],
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:41:29.875Z"
  },
  {
    "id": "7f8635f3-036c-4702-93e0-639c8ece7a6f",
    "type": "send",
    "sourceIP": "::1",
    "signers": [
      {
        "name": "Other Maksim",
        "email": "john.doe@domain.ltd"
      }
    ],
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:41:36.423Z"
  },
  {
    "id": "80b979bf-5726-4c1d-883c-b636af89d305",
    "type": "view",
    "sourceIP": "::1",
    "signers": [
      {
        "name": "Other Maksim",
        "email": "john.doe@domain.ltd"
      }
    ],
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:43:55.434Z"
  },
  {
    "id": "387a6dc6-1d1a-4de4-8af2-493e5e31e528",
    "type": "sign",
    "sourceIP": "::1",
    "signers": [
      {
        "name": "Other Maksim",
        "email": "john.doe@domain.ltd"
      }
    ],
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:44:02.889Z"
  },
  {
    "id": "a72b66ba-5c78-448d-8b0f-e9aee7635f25",
    "type": "complete",
    "sourceIP": null,
    "signers": null,
    "documentId": "cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc",
    "createdAt": "2022-03-30T11:44:02.889Z"
  }
];

export const exampleDocumentShareLink = {
  result: FRONTEND_URL + '/documents/cb9d98ec-d1b9-4d7f-9c5a-78eb505a03fc/download?hash=%242a%2410%24Ej4so8aCJUxvt9BkbgYtou9ce28ZcfaWXyB4rP2gmkj13krGqlokq'
};

export const exampleDownloadUrl = {
  result: 'https://example.com/download/some_file.pdf',
};

export const exampleShareDocument = {
  recipients: [{
    email: "john.doe@domain.ltd"
  }],
};

export const exampleRenameDocument = {
  title: 'New agreement',
};

export const exampleBulkSigningRequest = {
  title: "example document",
  message: "example message",
  signers: [
    {
      name: "signer1",
      email: "signer1@example.example",
    },
    {
      name: "signer2",
      email: "signer2@example.example",
    },
  ],
  testMode: true,
  templateId: "97066b2e-1853-4f3a-927c-2b61e454a58e",
};

export const exampleRecentDocument = {
  id: "db0d6b70-35b3-4095-a742-776527b7c1f0",
  title: "example document 5",
  message: "example message",
  signers: [
    {
      id: "97066b2e-1853-4f3a-927c-2b61e454a58e",
      name: "Me (Now)",
      email: "example@domain.ltd",
      order: -1,
      isPreparer: true,
      role: "Me (Now)",
      isReceivingReminders: true,
      signLink: null,
    },
    {
      id: "52c93852-7824-4c31-93e7-26984fcf3301",
      name: "Maksim Lebedev 2",
      email: "example2@domain.ltd",
      order: 1,
      isPreparer: false,
      role: "User",
      isReceivingReminders: true,
      signLink: "https://example.com/sign/user2/some_file.pdf",
    },
    {
      id: "2665605c-cf2d-461a-8003-50b60732a494",
      name: "Maksim Lebedev",
      email: "example3@domain.ltd",
      order: 2,
      isPreparer: false,
      role: "Admin",
      isReceivingReminders: true,
      signLink: "https://example.com/sign/user3/some_file.pdf",
    },
  ],
};

export const exampleSendSignRemindersRequest = {
  signersIds: ['387a6dc6-1d1a-4de4-8af2-493e5e31e528', 'fa482139-50c5-4a38-8edf-852c42eb732d']
};
