import { ApiRequestMethod } from "types";
import { ORIGIN_URL } from "./constants";
import { exampleUser } from "./example";

export const getUserRequest = {
  title: "Get user",
  type: "user",
  description: `
    This endpoints allows you to get
    information about your user account
  `,
  response: exampleUser,
  url: ORIGIN_URL + "/user/by-api",
  method: ApiRequestMethod.GET,
  attributes: [
    {
      name: "appSumoStatus",
      description: "AppSumo status",
      type: '"standard" \n| "full"',
    },
    {
      name: "avatarUrl",
      description: "Link on account avatar",
      type: "URL",
    },
    {
      name: "email",
      description: "User's email",
      type: "string",
    },
    {
      name: "id",
      description: "User's id",
      type: "UUID",
    },
    {
      name: "name",
      description: "User's name",
      type: "string",
    },
    {
      name: "status",
      description: "User's status",
      type: '"active" \n| "freeze"',
    },
    {
      name: "role",
      description: "User's role",
      type: '"owner" \n| "admin" \n| "user"',
    },
  ],
};
