export const recentDocumentAttributes = [
  { name: "id", description: "Document ID", type: "UUID" },
  { name: "title", description: "Document Title", type: "string" },
  { name: "message", description: "Message for signers", type: "string" },
  {
    name: "signers",
    description: "Array with information about signers",
    type: "Object[]",
  },
  {
    name: "signers[].id",
    description: "Signer's ID (not equals to User ID)",
    type: "UUID",
  },
  { name: "signers[].name", description: "Signer's name", type: "string" },
  { name: "signers[].email", description: "Signer's email", type: "string" },
  { name: "signers[].order", description: "Signing order", type: "number" },
  {
    name: "signers[].isPreparer",
    description: "Signer is preparer",
    type: "boolean",
  },
  { name: "signers[].role", description: "Signer role", type: "string" },
  {
    name: "signers[].isReceivingReminders",
    description: "Is signer receiving reminders",
    type: "boolean",
  },
  { name: "signers[].signLink", description: "Signing link", type: "URL" },
];
