export const exampleCreateTeamMembersResponse = [
  {
    id: "1964b33b-f60b-4104-af30-505e730a9d18",
    email: "john.doe@domain.ltd",
    name: "John Doe",
    role: "user",
  },
  {
    id: "c801cc9b-34b7-4099-a64d-bd1b12ebadf8",
    email: "example2@domain.ltd",
    name: "Joanna Doe",
    role: "admin",
  },
  {
    id: "d19c9167-4ee2-4dac-b966-e50ca15aad2e",
    email: "example3@domain.ltd",
    name: "James Doe",
    role: "user",
  },
];

export const exampleListTeamMembersResponse = {
  id: "39fea8da-e1e7-4c82-b964-ec6e1a98b3db",
  ownerId: "1964b33b-f60b-4104-af30-505e730a9d18",
  owner: {
    id: "1964b33b-f60b-4104-af30-505e730a9d18",
    email: "john.doe@domain.ltd",
    name: "John Doe",
    role: "owner",
  },
  members: [
    {
      id: "c801cc9b-34b7-4099-a64d-bd1b12ebadf8",
      email: "example2@domain.ltd",
      name: "Joanna Doe",
      role: "admin",
    },
    {
      id: "d19c9167-4ee2-4dac-b966-e50ca15aad2e",
      email: "example3@domain.ltd",
      name: "James Doe",
      role: "user",
    },
  ],
};

export const exampleAddTeamMemberRequest = {
  members: [
    {
      email: "example@domain.ltd",
      name: "John Doe",
      role: "user",
    },
    {
      email: "example2@domain.ltd",
      name: "Joanna Doe",
      role: "admin",
    },
    {
      email: "example3@domain.ltd",
      name: "James Doe",
    },
  ],
};

export const exampleRemoveTeamMembersRequest = {
  emails: ["example@domain.ltd"],
};
