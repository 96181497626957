import {ApiRequestMethod} from "../types";
import {ORIGIN_URL} from "./constants";
import { exampleSendSignRemindersRequest } from "./example";

export const sendSignRemindersRequest = {
  title: 'Send reminders',
  type: 'sendSignReminders',
  description: 'Send reminders to document\'s signers',
  method: ApiRequestMethod.POST,
  url: ORIGIN_URL + '/document_sign/documents/:id/send_reminders',
  path: [{
    name: 'id',
    description: 'Document ID',
    isRequired: true,
    type: 'UUID',
  }],
  parameters: [{
    name: 'signersIds',
    description: 'The array of signers\' IDs',
    isRequired: true,
    type: 'UUID[]'
  }],
  exampleRequest: exampleSendSignRemindersRequest,
};
