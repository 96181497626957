import React from 'react';
import { ApiResponseType } from 'types';
import { colorizeJSON } from 'utils/functions';

interface ApiReferenceResponseProps {
  response?: ApiResponseType;
}

export const ApiReferenceResponse = ({ response }: ApiReferenceResponseProps) => {
  return (
    <div
      className="apiReference__response"
      style={{ whiteSpace: 'pre-line', color: 'white' }}
    >
      {response && (
        <div className="apiReference__response-container">
          <span>Response:</span>
          <pre dangerouslySetInnerHTML={{ __html: colorizeJSON(response) }}></pre>
        </div>
      )}
    </div>
  );
};
