import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import Logo from '../../../Asserts/images/logo.svg';
import { TitleTypeData } from 'types';
import { UIButton } from 'Components/ui';
import History from 'Service/History';
import { HistoryService } from 'Service';
import { GET_FREE_API_KEY_URL } from 'constants/constants';

interface ApiReferenceSidebarProps {
  changeData: (type: string) => void;
  titleTypeData: TitleTypeData[];
}

export const ApiReferenceSidebar = ({ changeData, titleTypeData }: ApiReferenceSidebarProps) => {
  const [active, setActive] = useState<string>(titleTypeData[0].title || '');

  const handleChangeData = useCallback(
    (titleType: TitleTypeData) => {
      setActive(titleType.title);
      changeData(titleType.type);
    },
    [changeData, setActive],
  );

  const handleRedirectOnApiPage = useCallback(() => {
    HistoryService.redirect(GET_FREE_API_KEY_URL);
  }, []);

  return (
    <div className="apiReference__sidebar">
      <div className="apiReference__sidebar__wrapper">
        <ul className="apiReference__sidebar-list">
          <li className="apiReference__sidebar-logo">
            <img src={Logo} alt="Signaturely" />
          </li>
          {titleTypeData.map(titleType => (
            <li
              key={titleType.type}
              className={classNames('apiReference__sidebar-item', {
                active: active === titleType.title,
              })}
              onClick={() => handleChangeData(titleType)}
            >
              {titleType.title}
            </li>
          ))}
          <li>
             <a href={GET_FREE_API_KEY_URL} target="_blank" rel="noreferrer">
              <UIButton title="Get a Free API key" />
             </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
