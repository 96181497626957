export const documentAttributes = [
  {
    name: "codeAccess",
    description: "Access code for document",
    type: "string",
  },
  { name: "createdAt", description: "-", type: "timestamp" },
  { name: "deletedAt", description: "-", type: "timestamp" },
  { name: "errorText", description: "Text of error", type: "string" },
  { name: "fields", description: "Document fields", type: "Object" },
  { name: "filesUploaded", description: "Uploaded flag", type: "boolean" },
  { name: "id", description: "Document id", type: "UUID" },
  {
    name: "isFromFormRequest",
    description: "Form request flag",
    type: "boolean",
  },
  { name: "isOrdered", description: "Ordered flag", type: "boolean" },
  { name: "message", description: "Document message", type: "string" },
  {
    name: "originalFileName",
    description: "Original document filename",
    type: "string",
  },
  {
    name: "originalFileUrl",
    description: "Link for original document file",
    type: "URL",
  },
  { name: "parts", description: "Document parts", type: "Object" },
  {
    name: "pdfFileKey",
    description: "Link for document pdf file",
    type: "URL",
  },
  { name: "pdfMetadata", description: "metadata", type: "Object" },
  {
    name: "recipients",
    description: "Array of recipient emails",
    type: "string[]",
  },
  { name: "resultPdfFileKey", description: "-", type: "string" },
  {
    name: "shareLink",
    description: "Link for share yout documents",
    type: "URL",
  },
  { name: "signers", description: "List of signers", type: "Object" },
  {
    name: "status",
    description: "Status of document",
    type: '"draft" \n| "awaiting" \n| "completed" \n "declined"',
  },
  { name: "templateId", description: "Template ID", type: "UUID" },
  { name: "testMode", description: "Test mode flag", type: "boolean" },
  { name: "title", description: "Document title", type: "string" },
  {
    name: "type",
    description: "Document type",
    type: '"me" \n| "others" \n| "me_and_other"',
  },
  { name: "updatedAt", description: "Date of last update", type: "timestamp" },
];
