import React from 'react';

interface UIButtonProps {
  handleClick?: (value: any) => any;
  title: string;
}

const UIButton = ({
  handleClick,
  title
}: UIButtonProps) => {
  return (
    <div className="button">
      <button onClick={handleClick}>
        {title}
      </button>
    </div>
  );
}

export default UIButton;