import { ApiRequestMethod } from "types";
import { ORIGIN_URL } from "./constants";
import { documentAttributes } from "./documentAttributes";
import { exampleDocument } from "./example";

export const getDocumentListRequest = {
  title: "Get document list",
  type: "getDocumentList",
  description: `
    This endpoints allows you to get
    list of your documents
  `,
  response: {
    data: [
      { ...exampleDocument, folderId: "55e4e9b7-18f6-4255-8059-451b55ae279d" },
    ],
  },
  url: ORIGIN_URL + `/documents`,
  method: ApiRequestMethod.GET,
  query: [
    {
      name: "limit",
      isRequired: true,
      description: "Number of items presented on page",
      type: "number",
    },
    {
      name: "page",
      isRequired: true,
      description: "Number of page",
      type: "number",
    },
    {
      name: "type[]",
      description: "List of document types",
      type: '"me" \n| "others" \n| "me_and_other"',
    },
    {
      name: "status[]",
      description: "List of document statuses",
      type: '"draft" \n| "awaiting" \n| "completed"',
    },
    {
      name: "dateFrom",
      description: "-",
      type: "timestamp",
    },
    {
      name: "dateTo",
      description: "-",
      type: "timestamp",
    },
    {
      name: "orderingKey",
      description: "Sort by field with this name",
      type: "string",
    },
    {
      name: "orderingDirection",
      description: "ascending or descending",
      type: '"ASC" | "DESC"',
    },
    {
      name: "searchTerm",
      description: "String for searching",
      type: "string",
    },
    {
      name: "searchType",
      description: "Type of search",
      type: '"signers" | "documents"',
    },
    {
      name: "folderId",
      description:
        "Folder ID for search\n(null for root,\nID for certain folder,\nundefined for everywhere)",
      type: 'UUID | "null"',
    },
  ],
  attributes: [
    ...documentAttributes,
    { name: "folderId", type: "UUID", description: "Document's folder ID" },
    {
      name: "itemCount",
      type: "number",
      description: "Items count on the page",
    },
    {
      name: "totalItems",
      type: "number",
      description: "Total count",
    },
    {
      name: "itemsPerPage",
      type: "number",
      description: "Limit setting in request",
    },
    {
      name: "totalPages",
      type: "number",
      description: "Total pages count",
    },
    {
      name: "currentPage",
      type: "number",
      description: "Current page number",
    },
  ],
};
