import {ApiRequestMethod} from "../types";
import {ORIGIN_URL} from "./constants";
import {exampleShareDocument} from "./example";

export const shareDocumentRequest = {
  title: 'Share document',
  type: 'shareDocument',
  description: 'Share the document via email',
  method: ApiRequestMethod.POST,
  url: ORIGIN_URL + '/document_sign/documents/:id/share',
  path: [{
    name: 'id',
    description: 'ID of the document',
    type: 'UUID',
    isRequired: true,
  }],
  parameters: [{
    name: 'recipients',
    description: 'Array of objects with email field',
    isRequired: true,
    type: '[\n {\n  email: string\n }\n]'
  }],
  exampleRequest: exampleShareDocument,
};
