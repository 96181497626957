import {ApiRequestMethod} from "../../types";
import {ORIGIN_URL} from "../constants";

export const changeTeamMemberRoleRequest = {
  title: "Change Team Member Account Type",
  description:
    "Change Team Member Account Type API call will allow to change the account type of any user that is already a team member of your Signaturely account",
  type: "changeTeamMemberRole",
  method: ApiRequestMethod.PATCH,
  url: ORIGIN_URL + "/teams/members/role",
  parameters: [
    {
      name: "email",
      type: "string",
      isRequired: true,
    },
    {
      name: "role",
      type: '"user" | "admin"',
      isRequired: true,
    },
  ],
  attributes: [
    {
      name: "appSumoStatus",
      description: "AppSumo status",
      type: '"standard" | "full" | null',
    },
    {
      name: "avatarUrl",
      description: "Link on account avatar",
      type: "URL",
    },
    {
      name: "email",
      description: "User's email",
      type: "string",
    },
    {
      name: "id",
      description: "User's ID",
      type: "UUID",
    },
    {
      name: "name",
      description: "User's name",
      type: "string",
    },
    {
      name: "status",
      description: "User's status",
      type: '"active" | "freeze"',
    },
    {
      name: "role",
      description: "User's role",
      type: '"user" | "admin"',
    },
  ],
  exampleRequest: {
    email: "john.doe@domain.ltd",
    role: "admin",
  },
  response: {
    appSumoStatus: null,
    avatarUrl: null,
    email: "john.doe@domain.ltd",
    id: "d112370a-74b8-44de-84a5-ed124e9c174e",
    name: "username",
    status: "active",
    role: "admin",
  },
};
