import { ApiRequestMethod } from "types";
import { ORIGIN_URL } from "./constants";
import { documentAttributes } from "./documentAttributes";
import { exampleDocument, exampleSigningRequestReq } from "./example";

export const signingRequest = {
  title: "Create signing request",
  type: "signigRequest",
  description: `
    This endpoints allows you to create
    signing request for your documents
  `,
  response: {
    ...exampleDocument,
    pdfMetadata: null,
    pdfFileKey: null,
    parts: [],
    fields: [],
    status: "preparing",
    templateId: "db0d6b70-35b3-4095-a742-776527b7c1f0",
  },
  url: ORIGIN_URL + "/document_sign/api/request_by_template",
  method: ApiRequestMethod.POST,
  parameters: [
    {
      name: "title",
      description: "Document title",
      type: "string",
    },
    {
      name: "message",
      description: "Document message",
      type: "string",
    },
    {
      name: "signers",
      description:
        "List of signers. For ordered documents must defined order. 'fields' is an optional dictionary " +
        "with values for document fields with API tags. In our example there are three fields with API tags:" +
        ' "customerPhone" (textbox), "reason" (textbox), "agreeAllTerms" (checkbox)',
      isRequired: true,
      type: "{\n  name: string,\n  email: string,\n  role: string,\n  order: number,\n  fields: Object\n}",
    },
    {
      name: "isOrdered",
      description: "Document ordered flag",
      type: "boolean",
    },
    {
      name: "templateId",
      description: "Template id",
      type: "UUID",
    },
    {
      name: "testMode",
      description: "Test mode flag",
      type: "boolean",
    },
    {
      name: "folderId",
      description: "Folder ID",
      type: "UUID",
    },
  ],
  attributes: documentAttributes,
  exampleRequest: exampleSigningRequestReq,
};
