import {ApiRequestMethod} from "../../types";
import {ORIGIN_URL} from "../constants";
import { exampleRemoveTeamMembersRequest } from "./example";

export const deleteTeamMemberRequest = {
  title: 'Remove Team Members',
  description: 'Remove Team Members API call will allow to remove team members directly via API.',
  type: 'deleteTeamMember',
  method: ApiRequestMethod.DELETE,
  url: ORIGIN_URL + '/remove-teammates/email',
  parameters: [{
    name: 'emails',
    description: 'The emails of members to remove',
    type: 'string[]',
  }],
  exampleRequest: exampleRemoveTeamMembersRequest,
};
