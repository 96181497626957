import {ApiRequestMethod} from "../types";
import {ORIGIN_URL} from "./constants";
import {exampleBulkSigningRequest} from "./example";

export const bulkSigningRequest = {
  title: 'Bulk Send Signature Request based on Template',
  description: 'Bulk Send Template will allow for users to generate a bulk series of signature requests based on a single signer template and CSV data.',
  type: 'bulkSigning',
  method: ApiRequestMethod.POST,
  url: ORIGIN_URL + '/document_sign/api/bulk_send',
  parameters: [{
    name: 'title',
    description: 'Document title',
    type: 'string',
    isRequired: true,
  }, {
    name: 'message',
    description: 'Document message',
    type: 'string',
  }, {
    name: 'templateId',
    type: 'UUID',
    isRequired: true,
    description: "Template's ID",
  }, {
    name: 'signers',
    description: 'List of signers',
    type: '[\n  {\n    name: string,\n    email: string\n  }\n]',
    isRequired: true,
  }, {
    name: 'testMode',
    description: 'Test mode flag',
    type: 'boolean',
  }, {
    name: 'folderId',
    type: 'UUID',
    description: 'Folder ID'
  }],
  exampleRequest: exampleBulkSigningRequest,
  response: {}
};
