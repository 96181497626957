import { ApiRequestMethod } from "../../types";
import { ORIGIN_URL } from "../constants";

export const getFolderByIdRequest = {
  title: "Get Folder",
  description: "Get folder information via API call.",
  type: "getFolder",
  method: ApiRequestMethod.GET,
  url: ORIGIN_URL + "/api-integrations/folders/:id",
  path: [
    {
      name: "id",
      type: "UUID | null",
      isRequired: true,
      description: "Folder's ID ('null' for root)",
    },
  ],
  response: {
    id: "f8bd5c30-0724-49de-8e46-7793a41d1be3",
    title: "Awesome Title",
    parentId: "9e3c8438-1615-4786-913e-777005f1cd88",
    documentsCount: 10,
    foldersCount: 20,
  },
  attributes: [
    {
      name: "id",
      type: "UUID",
      description: "Folder's ID",
    },
    {
      name: "title",
      type: "string",
      description: "Folder's title",
    },
    {
      name: "parentId",
      type: "UUID",
      description: "Parent Folder ID (null if root)",
    },
    {
      name: "documentsCount",
      type: "number",
      description: "Number of documents in folder",
    },
    {
      name: "foldersCount",
      type: "number",
      description: "Number of folders within",
    },
  ],
};
