import { RouteDataType } from "types";
import { ApiReferenceAttribute } from "./ApiReferenceAttribute";
import { ApiReferenceParameter } from "./ApiReferenceParameter";
import { ApiReferenceResponse } from "./ApirReferenceResponse";

interface ApiReferenceDataProps {
  route: RouteDataType;
}

export const ApiReferenceData = ({ route }: ApiReferenceDataProps) => {
  return (
    <div className="apiReference__data-container">
      <div className="apiReference__data">
        <div className="apiReference__data-title">{route.title}</div>
        <div className="apiReference__data-description">
          {route.description}
        </div>
        <div className="apiReference__data-routes">
          <div className="apiReference__method">{route.method}</div>
          <div className="apiReference__url">{route.url}</div>
          {route.exampleRequest ? (
            <div className="apiReference__request-example">
              <span>Example:</span>
              <pre
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(route.exampleRequest, undefined, 2),
                }}
              />
            </div>
          ) : null}
          {route.path ? (
            <div className="apiReference__parameters">
              <div className="apiReference__parameters-title">
                Path Parameters
              </div>
              <div className="apiReference__table-header">
                <div>Parameter</div>
                <div>Description</div>
              </div>
              {route.path.map((path) => (
                <ApiReferenceParameter
                  key={`${route.type}-${path.name}`}
                  parameter={path}
                />
              ))}
            </div>
          ) : null}
          {route.query ? (
            <div className="apiReference__parameters">
              <div className="apiReference__parameters-title">
                Query Parameters
              </div>
              <div className="apiReference__table-header">
                <div>Parameter</div>
                <div>Description</div>
              </div>
              {route.query.map((query) => (
                <ApiReferenceParameter
                  key={`${route.type}-${query.name}`}
                  parameter={query}
                />
              ))}
            </div>
          ) : null}
          {route.parameters ? (
            <div className="apiReference__parameters">
              <div className="apiReference__parameters-title">Body Parameters</div>
              <div className="apiReference__table-header">
                <div>Parameter</div>
                <div>Description</div>
              </div>
              {route.parameters.map((parameter) => (
                <ApiReferenceParameter
                  key={`${route.type}-${parameter.name}`}
                  parameter={parameter}
                />
              ))}
            </div>
          ) : null}
          {route.attributes ? (
            <div className="attributes">
              <div className="apiReference__parameters-title">Attributes</div>
              <div className="apiReference__table-header">
                <div>Attribute</div>
                <div>Description</div>
              </div>
              {route.attributes.map((attribute) => (
                <ApiReferenceAttribute
                  key={`${route.type}-${attribute.name}`}
                  attribute={attribute}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <ApiReferenceResponse response={route.response} />
    </div>
  );
};
