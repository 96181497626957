import { ORIGIN_URL } from "constants/constants";
import { ApiReferenceResponse } from "Pages/ApiReference/components/ApirReferenceResponse";
import { ApiRequestMethod } from "types";

const responseObject = {
  data: {
    id: "b073d479-99d8-4c9b-a4b5-e18111d69055",
    webhookEvents: ["document_sent", "document_completed"],
    hookUrl: "https://mydomain.com/webhook",
    userId: "9e185f18-de27-487f-9a87-df9d1f5b8d34",
    createdAt: new Date().toDateString(),
  },
};

const Webhook = () => {
  return (
    <div className="apiReference__data-container">
      <div className="apiReference__data">
        <div className="apiReference__webhook-data">
          <div className="apiReference__data-title">Subscribe</div>
          <div className="apiReference__data-description">
            This endpoints allows you to subscribe on webhook events
          </div>
          <div className="apiReference__method">{ApiRequestMethod.POST}</div>
          <div className="apiReference__url">{`${ORIGIN_URL}/hooks/subscribe`}</div>
          <div className="apiReference__request-example">
            <span>Example:</span>
            <pre
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                  {
                    hookUrl: "https://mydomain.com/webhook",
                    webhookEvents: ["document_sent", "document_completed"],
                  },
                  undefined,
                  2
                ),
              }}
            />
          </div>
          <div className="apiReference__webhook-parameters">
            <div className="apiReference__parameters-title">
              Body Parameters
            </div>
            <div className="apiReference__table-header">
              <div>Parameter</div>
              <div>Description</div>
            </div>
            <div className="apiReference__parameter">
              <div className="apiReference__parameter-item apiReference__parameter-name">
                <span>hookUrl</span>
              </div>
              <div className="apiReference__parameter-item apiReference__parameter-description">
                <span>Required</span>
                <div>URL to subscribe</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  URL
                </pre>
              </div>
            </div>
            <div className="apiReference__parameter">
              <div className="apiReference__parameter-item apiReference__parameter-name">
                <span>{"webhookEvents[]"}</span>
              </div>
              <div className="apiReference__parameter-item apiReference__parameter-description">
                <span>Required</span>
                <div>
                  List of subscribing webhook events{" "}
                  <pre className="apiReference__parameter-item apiReference__parameter-type">
                    "document_sent"
                    <br />| "document_completed"
                  </pre>
                </div>
              </div>
            </div>
          </div>
          <div className="apiReference__attributes">
            <div className="apiReference__parameters-title">Attributes</div>
            <div className="apiReference__table-header">
              <div>Attribute</div>
              <div>Description</div>
            </div>
            <div className="apiReference__attribute">
              <div className="apiReference__attribute-item apiReference__attribute-name">
                <span>id</span>
              </div>
              <div className="apiReference__attribute-item apiReference__attribute-description">
                <div>Webhook's ID</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  UUID
                </pre>
              </div>
            </div>
            <div className="apiReference__attribute">
              <div className="apiReference__attribute-item apiReference__attribute-name">
                <span>{"webhookEvents[]"}</span>
              </div>
              <div className="apiReference__attribute-item apiReference__attribute-description">
                <div>List of subscribed events</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  "document_sent" <br />| "document_completed"
                </pre>
              </div>
            </div>
            <div className="apiReference__attribute">
              <div className="apiReference__attribute-item apiReference__attribute-name">
                <span>hookUrl</span>
              </div>
              <div className="apiReference__attribute-item apiReference__attribute-description">
                <div>Subscribed webhook url</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  URL
                </pre>
              </div>
            </div>
            <div className="apiReference__attribute">
              <div className="apiReference__attribute-item apiReference__attribute-name">
                <span>userId</span>
              </div>
              <div className="apiReference__attribute-item apiReference__attribute-description">
                <div>User's ID</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  UUID
                </pre>
              </div>
            </div>
            <div className="apiReference__attribute">
              <div className="apiReference__attribute-item apiReference__attribute-name">
                <span>createdAt</span>
              </div>
              <div className="apiReference__attribute-item apiReference__attribute-description">
                <div>Webhook creation date</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  Date
                </pre>
              </div>
            </div>
          </div>
          <div className="apiReference__data-title">Unsubscribe</div>
          <div className="apiReference__data-description">
            This endpoints allows you to unsubscribe from webhook events
          </div>
          <div className="apiReference__method">{ApiRequestMethod.DELETE}</div>
          <div className="apiReference__url">{`${ORIGIN_URL}/hooks/unsubscribe/:id`}</div>
          <div className="apiReference__webhook-parameters">
            <div className="apiReference__parameters-title">
              Path parameters
            </div>
            <div className="apiReference__table-header">
              <div>Parameter</div>
              <div>Description</div>
            </div>
            <div className="apiReference__parameter">
              <div className="apiReference__parameter-item apiReference__parameter-name">
                <span>id</span>
              </div>
              <div className="apiReference__parameter-item apiReference__parameter-description">
                <span>Required</span>
                <div>ID of unsubscribing webhook</div>
                <pre className="apiReference__parameter-item apiReference__parameter-type">
                  UUID
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApiReferenceResponse response={responseObject} />
    </div>
  );
};

export default Webhook;
