import {ORIGIN_URL} from "./constants";
import {exampleDocument} from "./example";
import {ApiRequestMethod} from "../types";
import {documentAttributes} from "./documentAttributes";

export const listTemplatesRequest = {
  title: "List templates",
  type: "listTemplates",
  description: `
    This endpoints allows you to get
    list of your templates available in API
  `,
  response: { data: [exampleDocument] },
  url: ORIGIN_URL + `/documents/api/templates`,
  method: ApiRequestMethod.GET,
  attributes: documentAttributes,
  query: [
    {
      name: "withTeammateTemplates",
      description: "List templates with teammates' templates",
      type: "boolean",
    },
    {
      name: "limit",
      description: "Number of items presented on page",
      isRequired: true,
      type: "number",
    },
    {
      name: "page",
      description: "Number of page",
      isRequired: true,
      type: "number",
    },
  ],
};
