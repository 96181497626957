interface ApiReferenceAttributeProps {
  attribute: any;
}

export const ApiReferenceAttribute = ({
  attribute,
}: ApiReferenceAttributeProps) => {
  return (
    <div
      className="apiReference__attribute"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="apiReference__attribute-item apiReference__attribute-name">
        <span>{attribute.name}</span>
      </div>
      <div className="apiReference__attribute-item apiReference__attribute-description">
        <div>{attribute.description}</div>
        {attribute.type && (
          <div className="apiReference__parameter-type">
            <pre dangerouslySetInnerHTML={{ __html: attribute.type }}></pre>
          </div>
        )}
      </div>
    </div>
  );
};
