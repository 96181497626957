import { ApiRequestMethod } from "../../types";
import { ORIGIN_URL } from "../constants";
import { exampleListTeamMembersResponse } from "./example";

export const getTeamMembersRequest = {
  title: "List Team Members",
  description:
    "List Team Members API call will return a list of all the actual team users in the Signaturely account, with their respective account types.",
  type: "getTeamMembers",
  url: ORIGIN_URL + "/teams/members",
  method: ApiRequestMethod.GET,
  response: exampleListTeamMembersResponse,
  attributes: [
    {
      name: "id",
      description: "Team's ID",
      type: "UUID",
    },
    {
      name: "ownerId",
      description: "Team owner's ID",
      type: "UUID",
    },
    {
      name: "owner",
      description: "Object represents an owner information",
      type: '{\n id: UUID,\n email: string,\n name: string,\n role: "owner"\n}',
    },
    {
      name: "members",
      description: "Team members",
      type: '[\n {\n  id: UUID,\n  email: string,\n  name: string,\n  role: "user" | "admin"\n }\n]',
    },
  ],
};
