import {getTeamMembersRequest} from "./getTeamMembersRequest";
import {addTeamMemberRequest} from "./addTeamMemberRequest";
import {deleteTeamMemberRequest} from "./deleteTeamMemberRequest";
import {changeTeamMemberRoleRequest} from "./changeTeamMemberRoleRequest";

const teams = {
  getTeamMembers: getTeamMembersRequest,
  addTeamMember: addTeamMemberRequest,
  deleteTeamMember: deleteTeamMemberRequest,
  changeTeamMemberRole: changeTeamMemberRoleRequest,
}

export default teams;
