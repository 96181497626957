import {ApiRequestMethod} from "../types";
import {ORIGIN_URL} from "./constants";
import {exampleDownloadUrl} from "./example";

export const getTemplateFileRequest = {
  title: 'Get Template File',
  description: 'Get Template File API call will return a PDF, a template previously created on your Signaturely account. The result will be an unedited PDF that the user originally uploaded.',
  type: 'getTemplateFile',
  method: ApiRequestMethod.GET,
  url: ORIGIN_URL + '/document_sign/documents/:templateId/signed_download_url/api',
  path: [{
    name: 'templateId',
    isRequired: true,
    description: 'Template ID',
    type: 'UUID',
  }],
  attributes: [{
    name: 'result',
    description: 'Link to the document view',
    type: 'URL',
  }],
  response: exampleDownloadUrl,
};
