import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { RouteDataType } from 'types';
import { ApiReferenceData } from './components/ApiReferenceData';
import { ApiReferenceSidebar } from './components/ApiReferenceSidebar';
import Data from '../../constants/index';
import { isWebhookDataType } from 'utils/guards';
import { Webhook } from 'Components/Webhook';

const ApiReference = () => {
  const [data, setData] = useState<RouteDataType | null>(null);

  useEffect(() => {
    !data && setData(Data['user']);
  }, [data]);

  const handleChangeData = useCallback((type: string) => {
    setData(Data[type]);
  }, []);

  return (
    <div className="apiReference">
      {data && 
        <div className="apiReference__wrapper">
          <ApiReferenceSidebar changeData={handleChangeData}
            titleTypeData={
              Object.keys(Data).map(dataKey => ({
                title: Data[dataKey].title,
                type: Data[dataKey].type,
              }))
            }
          />
          {isWebhookDataType(data) ? <Webhook /> : <ApiReferenceData route={data} />}
        </div>
      }
    </div>
  );
};

export default ApiReference;
