import { createFolderRequest } from "./createFolderRequest";
import { getFolderByIdRequest } from "./getFolderByIdRequest";
import { getFolderListRequest } from "./getFolderListRequest";
import { moveEntitiesToFolderRequest } from "./moveEntitiesToFolder";
import { renameFolderByIdRequest } from "./renameFolderByIdRequest";

const teams = {
  getFolderList: getFolderListRequest,
  getFolder: getFolderByIdRequest,
  createFolder: createFolderRequest,
  renameFolder: renameFolderByIdRequest,
  moveEntitiesToFolder: moveEntitiesToFolderRequest,
};

export default teams;
