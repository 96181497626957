export type ApiDataType = {
  [key: string]: RouteDataType,
}

export enum ApiRequestMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
};

export type RouteDataType = {
  title: string;
  type: string;
  description: string;
  response: ApiResponseType;
  url: string;
  method: ApiRequestMethod;
  parameters?: ApiParametersType[];
  query?: ApiQueryType[];
  path?: ApiQueryType[];
  attributes?: ApiAttributesType[];
  exampleRequest?: ApiDataExampleRequestType;
};

export type ApiDataExampleRequestType = {
  [key: string]: any;
}

export type ApiResponseType = {
  data: ApiDataResponseType;
}

export type ApiDataResponseType = {
  [key: string]: any;
}

export type ApiQueryType = {
  name: string;
  description: string;
  type: string;
}

export type ApiParametersType = {
  name: string;
  description: string;
  isRequired: boolean;
  type: string;
}

export type ApiAttributesType = {
  name: string;
  description: string;
  type?: string;
}

export type TitleTypeData = Pick<RouteDataType, 'title' | 'type'>