import { ApiRequestMethod } from "types";
import { ORIGIN_URL } from "./constants";
import { exampleRecentDocument } from "./example";
import { recentDocumentAttributes } from "./recentDocumentAttributes";

export const getRecentDocumentRequest = {
  title: 'Get recent document',
  type: 'getRecentDocument',
  description: `
    This endpoints allows you to get
    your recent sended or completed document
  `,
  response: exampleRecentDocument,
  url: ORIGIN_URL + '/api-integrations/recent_document?recentDocumentType=sent',
  method: ApiRequestMethod.GET,
  query: [
    {
      name: 'recentDocumentType',
      type: '"sent" || "completed"',
      isRequired: true,
    }
  ],
  attributes: recentDocumentAttributes,
};
