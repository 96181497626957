import { ApiRequestMethod } from "../../types";
import { ORIGIN_URL } from "../constants";

export const createFolderRequest = {
  title: "Create Folder",
  description: "Create folder for documents via API call.",
  type: "createFolder",
  method: ApiRequestMethod.POST,
  url: ORIGIN_URL + "/api-integrations/folders",
  parameters: [
    {
      name: "title",
      type: "string",
      isRequired: true,
      description: "New folder's title",
    },
    {
      name: "parentId",
      type: "UUID",
      description:
        "Parent Folder ID (if not defined, then parent id will be null)",
    },
  ],
  exampleRequest: {
    title: "Awesome Title",
    parentId: "9e3c8438-1615-4786-913e-777005f1cd88",
  },
  response: {
    id: "f8bd5c30-0724-49de-8e46-7793a41d1be3",
    title: "Awesome Title",
    parentId: "9e3c8438-1615-4786-913e-777005f1cd88",
    documentsCount: 0,
    foldersCount: 0,
  },
  attributes: [
    {
      name: "id",
      type: "UUID",
      description: "Folder's ID",
    },
    {
      name: "title",
      type: "string",
      description: "Folder's title",
    },
    {
      name: "parentId",
      type: "UUID",
      description: "Parent Folder ID (null if root)",
    },
    {
      name: "documentsCount",
      type: "number",
      description: "Number of documents in folder",
    },
    {
      name: "foldersCount",
      type: "number",
      description: "Number of folders within",
    },
  ],
};
