import { ApiRequestMethod } from "types";
import { ORIGIN_URL } from "./constants";
import { exampleSigner } from "./example";

export const getSignersRequest = {
  title: "Get signer list",
  type: "getSigners",
  description: `
    This endpoints allows you to get
    list of signers for your document
  `,
  response: { data: [exampleSigner.data] },
  url: ORIGIN_URL + "/documents/:documentId/signers",
  method: ApiRequestMethod.GET,
  path: [
    {
      name: "documentId",
      description: `Id of the Document`,
      isRequired: true,
      type: "UUID",
    },
  ],
  attributes: [
    { name: "id", description: "Signer's ID", type: "UUID" },
    { name: "email", description: "Signer's email", type: "string" },
    { name: "name", description: "Signer's name", type: "string" },
    { name: "order", description: "Signing order", type: "number" },
    { name: "role", description: "Signer's role", type: "string" },
  ],
};
