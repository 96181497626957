import { ApiRequestMethod } from "../../types";
import { ORIGIN_URL } from "../constants";
import {
  exampleAddTeamMemberRequest,
  exampleCreateTeamMembersResponse,
} from "./example";

export const addTeamMemberRequest = {
  title: "Add Team Member",
  description:
    "Add Team Member API call will allow to add new team members directly via API.",
  type: "addTeamMember",
  method: ApiRequestMethod.POST,
  url: ORIGIN_URL + "/add-teammates/with-role",
  parameters: [
    {
      name: "members",
      description: "Information about members.",
      isRequired: true,
      type: `[\n {\n  email: string,\n  name: string,\n  role?: "user" | "admin"\n }\n]`,
    },
  ],
  exampleRequest: exampleAddTeamMemberRequest,
  response: exampleCreateTeamMembersResponse,
  attributes: [
    {
      name: "id",
      description: "Team member's ID",
    },
    {
      name: "email",
      description: "Team member's email",
    },
    {
      name: "name",
      description: "Team member's name",
    },
    {
      name: "role",
      description: "Team member's role",
    },
  ],
};
