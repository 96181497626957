import { ApiRequestMethod } from "types";
import { ORIGIN_URL } from "../constants";

export const getFolderListRequest = {
  title: "Get folder list",
  type: "getFolderList",
  description: `
    This endpoints allows you to get
    list of your documents
  `,
  response: {
    items: [
      {
        id: "f8bd5c30-0724-49de-8e46-7793a41d1be3",
        title: "Folder 1",
        documentsCount: 30,
        foldersCount: 15,
      },
    ],
    itemCount: 1,
    totalItems: 3,
    itemsPerPage: 1,
    totalPages: 3,
    currentPage: 1,
  },
  url: ORIGIN_URL + `/api-integrations/folders`,
  method: ApiRequestMethod.GET,
  query: [
    {
      name: "limit",
      description: "Number of items presented on page",
      isRequired: true,
      type: 'number',
    },
    {
      name: "page",
      description: "Number of page",
      isRequired: true,
      type: 'number',
    },
    {
      name: "folderId",
      description: "Start point of search (root if not present)",
      type: 'UUID',
    },
    {
      name: "orderingKey",
      description: "Order by certain field",
      type: '"title" \n| "id" \n| "foldersCount" \n| "documentsCount"',
    },
    {
      name: "orderingDirection",
      description: "Ascending or descending",
      type: '"ASC" \n| "DESC"',
    },
  ],
  attributes: [
    {
      name: "id",
      type: "UUID",
      description: "Folder's ID",
    },
    {
      name: "title",
      type: "string",
      description: "Folder's title",
    },
    {
      name: "documentsCount",
      type: "number",
      description: "Number of documents in folder",
    },
    {
      name: "foldersCount",
      type: "number",
      description: "Number of folders within",
    },
    {
      name: "itemCount",
      type: "number",
      description: "Items count on the page",
    },
    {
      name: "totalItems",
      type: "number",
      description: "Total count",
    },
    {
      name: "itemsPerPage",
      type: "number",
      description: "Limit setting in request",
    },
    {
      name: "totalPages",
      type: "number",
      description: "Total pages count",
    },
    {
      name: "currentPage",
      type: "number",
      description: "Current page number",
    },
  ],
};
