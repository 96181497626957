import { ApiRequestMethod } from "../types";
import { ORIGIN_URL } from "./constants";
import { exampleDownloadUrl } from "./example";

export const getCurrentDocumentDownloadLinkRequest = {
  title: "Get Document File",
  description:
    "Get Document File API call will return a PDF. If the Document completed, you will receive signed PDF, else you will receive source PDF.",
  type: "getCurrentDocumentDownloadLink",
  response: exampleDownloadUrl,
  method: ApiRequestMethod.GET,
  url:
    ORIGIN_URL + "/document_sign/documents/:documentId/signed_download_url/api",
  path: [
    {
      name: "documentId",
      description: "Document ID",
      type: "UUID",
      isRequired: true,
    },
  ],
  attributes: [
    {
      name: "result",
      description: "Link to the document file",
      type: "URL"
    },
  ],
};
