import {ORIGIN_URL} from "./constants";
import {exampleDocument, exampleRenameDocument} from "./example";
import {ApiRequestMethod} from "../types";
import {documentAttributes} from "./documentAttributes";

export const renameDocumentRequest = {
  title: 'Rename Document',
  type: 'renameDocument',
  url: ORIGIN_URL + '/documents/:id/rename',
  response: exampleDocument,
  method: ApiRequestMethod.PATCH,
  path: [{
    name: 'id',
    description: 'Document ID',
    type: 'UUID',
    isRequired: true,
  }],
  parameters: [{
    name: 'title',
    description: 'The new title for your document',
    type: 'string',
    isRequired: true,
  }],
  attributes: documentAttributes,
  exampleRequest: exampleRenameDocument,
};
