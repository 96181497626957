import { ApiRequestMethod } from "../../types";
import { ORIGIN_URL } from "../constants";

export const moveEntitiesToFolderRequest = {
  title: "Move entities to folder",
  description: "Move documents or folders into specific folder.",
  type: "moveEntitiesToFolder",
  method: ApiRequestMethod.PATCH,
  url: ORIGIN_URL + "/api-integrations/folders/move",
  parameters: [
    {
      name: "parentId",
      type: "UUID | null",
      description: "Folder's ID ('null' for root)",
    },
    {
      name: "entityIds",
      type: "UUID[]",
      isRequired: true,
      description: "IDs of folders/documents that you want to move",
    },
  ],
  exampleRequest: {
    parentId: "55e4e9b7-18f6-4255-8059-451b55ae279d",
    entityIds: [
      "d3093a6c-4164-4c4b-b3f7-344857a8ebc6",
      "b3abff61-0780-4e59-a97d-0ac07ef1ca2a",
      "dcc5c488-6e8d-4a5e-9f13-2ecc6deb2443",
    ],
  },
  response: {},
};
